.resource-container {
  padding: 40px;
  text-align: center;
  background-color: #f9f9f9;
}

.resource-container h1 {
  color: #003366;
  margin-bottom: 20px;
}

.resource-container p {
  font-size: 1rem;
  color: #444;
  margin-bottom: 30px;
}

.resource-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  justify-content: center;
  padding: 20px 0;
}

.resource-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.resource-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.resource-card img {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  object-fit: contain; /* Asegura que el icono mantenga su proporción */
}

.resource-card h2 {
  font-size: 1.2rem;
  color: #003366;
  margin-bottom: 10px;
}

.resource-card p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.resource-card a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.resource-card a:hover {
  background-color: #0056b3;
}

.btn-primary, .btn-secondary {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #f9f9f9;
  color: #004080;
  border: 1px solid #004080;
  margin-top: 20px;
}

.btn-secondary:hover {
  background-color: #004080;
  color: white;
}

.see-more {
  text-align: center;
  margin-top: 20px;
}

.icon {
  width: 24px; /* Ajusta según sea necesario */
  height: 24px;
}

.form-container {
  margin-top: 30px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

form div {
  margin-bottom: 15px;
}

form input,
form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
}

form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
}

form button:hover {
  background-color: #0056b3;
}



