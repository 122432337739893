.thank-you-page {
  text-align: center;
  margin: 50px auto;
  max-width: 600px;
  font-family: Arial, sans-serif;
}

.thank-you-icon {
  font-size: 50px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2em;
  color: #333;
}

p {
  font-size: 1.2em;
  margin: 10px 0;
}

.webinar-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.webinar-link:hover {
  background-color: #0056b3;
}

