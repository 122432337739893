.about-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
}

.about-header {
  text-align: center;
  margin-bottom: 40px;
}

.about-header h1 {
  font-size: 2.5rem;
  color: #003366;
}

.section {
  margin-bottom: 40px;
}

.section img {
  float: left;
  margin-right: 20px;
  width: 200px;
  height: auto;
  border-radius: 10px;
}

.section h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #004080;
}

.section p {
  color: #333;
  line-height: 1.6;
}
