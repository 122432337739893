/* styles.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
  background-color: #003366; /* O el color que uses */
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
}

/* Reset básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Tipografía general */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
  line-height: 1.6;
}

/* Enlaces */
a {
  color: #004080;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Títulos */
h1, h2, h3 {
  color: #003366;
  margin-bottom: 20px;
}

/* Contenedores comunes */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center; /* Alinea el contenido al centro */
}

/* Estilos comunes de botón */
button {
  background-color: #004080;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #003366;
}

/* styles.css */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ocupa toda la altura de la ventana */
  font-size: 18px;
  color: #003366; /* Alineado con tu estilo */
}


/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .container {
    padding: 15px;
  }
  
   .cta-btn {
    font-size: 1rem;
    padding: 10px 20px; /* Ajuste del tamaño del botón para pantallas pequeñas */
  }

  h1 {
    font-size: 1.8rem;
  }

  button {
    width: 100%; /* Para que los botones ocupen todo el ancho en pantallas pequeñas */
  }
  
  .center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  min-height: 100vh; /* Centrado vertical */
  width: 90%; /* Controlar el ancho */
  max-width: 800px; /* Opcional: ajusta según prefieras */
}
  
  
}

