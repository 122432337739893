/* Centrar el contenido y añadir estilos a la imagen */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.register-btn {
  background-color: #004080;
  color: white !important;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  text-transform: uppercase;
  transition: background-color 0.35s ease;
  cursor: pointer;
  margin-right: 10px;
}

.register-btn a {
  color: white !important;
  text-decoration: none;
}

.register-btn:hover {
  background-color: #003366;
}

.cta-btn {
  display: inline-block;
  background-color: #004080;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.1rem;
  margin-top: 20px;
   transition: background-color 0.3s ease; /* Suaviza el cambio de color */
}

.cta-btn:hover {
  background-color: #002850; /* Fondo más oscuro para mejorar el contraste */
  color: #ffffff; /* Mantener el color de texto blanco para mejor visibilidad */
}

/* Ajustar estilo del texto introductorio */
.intro-text {
  font-size: 1.2rem; /* Tamaño ligeramente mayor */
  line-height: 1.6; /* Mejora el espaciado entre líneas */
  max-width: 600px; /* Limita el ancho para que sea más compacto */
  margin: 10px auto; /* Espaciado superior e inferior */
  text-align: center; /* Asegura que el texto esté centrado */
  font-weight: 500; /* Usa un peso intermedio para destacar sin exagerar */
  color: #333; /* Un color más oscuro para mejor visibilidad */
}










